<template>
  <div class="">
    <v-btn
      rounded
      color="primary"
      @click.prevent="onAssignTemplates"
      class="mx-4 my-4 float-right"
      :disabled="!form.template_id.length"
    >
      {{ $t("Assign selected projects") }}
    </v-btn>

    <wrapper-drawer
      v-model="isAssignTemplate"
      :title="$t('Assign selected projects')"
    >
      <wrapper-form v-model="isFormAssignValid" :model="form">
        <v-row>
          <v-col cols="12" col>
            <p>
              {{ $t("Select assign taks") }}
            </p>
            <project-select
              v-model="form.project_id"
              multiple
              :rules="[validators.required]"
            />
          </v-col>
          <v-col cols="12" col>
            <v-btn
              color="primary"
              type="submit"
              :disabled="!isFormAssignValid"
              @click.prevent="assignTemplatesToProjects"
            >
              {{ $t("Assign in block") }}
            </v-btn>
          </v-col>
        </v-row>
        <small class="d-block mt-4">*{{ $t("MESSAGE_TEMPLATE_ASSIGN") }}</small>
      </wrapper-form>
    </wrapper-drawer>
  </div>
</template>

<script>
import {
  reactive,
  ref,
  computed,
  getCurrentInstance,
} from "@vue/composition-api";
import WrapperDrawer from "@/components/general/WrapperDrawer";
import WrapperForm from "@/components/general/WrapperForm";
import ProjectSelect from "@/components/projects/ProjectSelect";
import store from "@/store";
import { required } from "@core/utils/validation";
import api from "@/services";

export default {
  name: "TemplateAssignToProjects",
  components: {
    WrapperDrawer,
    WrapperForm,
    ProjectSelect,
  },
  setup(props) {
    const vm = getCurrentInstance().proxy;

    const isAssignTemplate = ref(false);
    const isFormAssignValid = ref(false);

    const templateId = computed(
      () => store.getters["templates/assignTemplateId"]
    );

    const form = reactive({
      project_id: null,
      template_id: templateId,
    });

    const onAssignTemplates = () => {
      isAssignTemplate.value = true;
    };

    const assignTemplatesToProjects = async () => {
      try {
        const { data: response } = await api.assignTemplatesToProjects(form);
        const { data, success } = response;
        vm.$alert(vm.$t("MessageSuccess"), null, "success");
      } catch (error) {
        vm.$alert(vm.$t("MessageError"), null, "error");
      }
    };

    return {
      form,
      isAssignTemplate,
      isFormAssignValid,

      validators: {
        required,
      },

      onAssignTemplates,
      assignTemplatesToProjects,
    };
  },
};
</script>

<style></style>
