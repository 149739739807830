<template>
  <wrapper-table :title="t('Templates manager')">
    <template #subtitle>
      <a-link
        :title="t('Create new template')"
        :icon="mdiPlus"
        @click.prevent="onAddTemplates"
      />
    </template>
    <template #actions-header>
      <template-assign-to-projects />
    </template>
    <template-list />

    <wrapper-drawer v-model="isAddTemplate" :title="t('Create new template')">
      <template-form is-add />
    </wrapper-drawer>
  </wrapper-table>
</template>

<script>
import { reactive, ref } from "@vue/composition-api";
import TemplateList from "@/components/templates/TemplateList";
import WrapperTable from "@/components/general/WrapperTable";
import FormTextArea from "@/components/forms/TextArea";
import WrapperDrawer from "@/components/general/WrapperDrawer";
import ALink from "@/components/general/ALink";
import { required } from "@core/utils/validation";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";
import { mdiPlus } from "@mdi/js";
import TemplateForm from "@/components/templates/TemplateForm";
import TemplateAssignToProjects from "@/components/templates/TemplateAssignToProjects";

export default {
  components: {
    TemplateList,
    WrapperTable,
    WrapperDrawer,
    FormTextArea,
    ALink,
    TemplateForm,
    TemplateAssignToProjects,
  },

  setup(props) {
    const { t } = useUtilsI18n();
    const isAddTemplate = ref(false);

    const onAddTemplates = () => {
      isAddTemplate.value = true;
    };

    const form = reactive({
      title_area: null,
      description_area: null,
    });

    return {
      form,
      isAddTemplate,

      mdiPlus,

      validators: {
        required,
      },

      onAddTemplates,
      t,
    };
  },
};
</script>

<style></style>
