<template>
  <div class="">
    <v-data-table
      :headers="headers"
      :items="templates"
      :items-per-page="5"
      item-key="id"
      show-select
      :loading="loading"
      @click:row="onEditRowItem"
      @item-selected="onItemSelect"
      @toggle-select-all="onToggleSelectAll"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template #[`item.area`]="{ item }">
        <div class="d-flex align-center">
          <icon-avatar :text="getNameAreas(item.area)" color="primary" />
          <div class="d-flex flex-column ms-3">
            <span
              class="d-block font-weight-semibold text--primary text-truncate"
              >{{ getNameAreas(item.area) }}</span
            >
          </div>
        </div>
      </template>

      <template #[`item.actions`]="{ index, item }">
        <button-delete @click.stop="onDeleteRowItem(index, item)" />
      </template>
    </v-data-table>

    <wrapper-drawer v-model="isEditTemplate" :title="t('Edit template')">
      <template-form
        is-edit
        :model="templateSelect"
        v-if="templateSelect"
        @on-template-edit="onTemplateEdit"
      />
    </wrapper-drawer>
  </div>
</template>

<script>
import { watch, ref, computed, onMounted } from "@vue/composition-api";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";
import IconAvatar from "@/components/icons/IconAvatar";
import ButtonDelete from "@/components/buttons/ButtonDelete";
import WrapperDrawer from "@/components/general/WrapperDrawer";
import WrapperForm from "@/components/general/WrapperForm";
import FormTextArea from "@/components/forms/TextArea";
import AreaSelect from "@/components/areas/AreaSelect";
import TemplateForm from "@/components/templates/TemplateForm";
import { required } from "@core/utils/validation";
import api from "@/services";
import store from "@/store";

export default {
  name: "TemplateList",
  components: {
    IconAvatar,
    ButtonDelete,
    WrapperDrawer,
    WrapperForm,
    FormTextArea,
    AreaSelect,
    TemplateForm,
  },
  setup(props) {
    const { t } = useUtilsI18n();
    const templates = ref([]);
    const isEditTemplate = ref(false);
    const templateSelect = ref(null);
    const loading = ref(false);

    const headers = computed(() => {
      return [
        { text: t("Areas"), value: "area" },
        { text: t("Templates"), value: "title" },
        { text: "", value: "actions" },
      ];
    });

    const onEditRowItem = (item) => {
      templateSelect.value = { ...item };

      isEditTemplate.value = true;
    };

    const onDeleteRowItem = async (index, { id: templateId }) => {
      try {
        await api.deleteTemplatesById(templateId);
        templates.value.splice(index, 1);
      } catch (error) {}
    };

    const getNameAreas = ({ name = "" }) => {
      return name;
    };

    const getTemplates = async () => {
      try {
        loading.value = true;
        const { data: response } = await api.getTemplates();
        const { data } = response;
        templates.value = data;
      } catch (error) {
      } finally {
        loading.value = false;
      }
    };

    const onTemplateEdit = ({ template }) => {
      try {
        const templateIndex = templates.value.findIndex(
          (i) => i.id === template.id
        );
        templates.value.splice(templateIndex, 1, { ...template });
      } catch (error) {}
    };

    const onItemSelect = (item) => {
      store.dispatch("templates/onItemAssignTemplates", item);
    };

    const onToggleSelectAll = (items) => {
      store.dispatch("templates/onAllAssignTemplates", items);
    };

    watch(isEditTemplate, (v) => {
      if (!v) templateSelect.value = null;
    });

    watch(
      () => store.state.templates.hasNewTemplate,
      (value) => {
        templates.value.unshift(value);
      }
    );

    onMounted(() => {
      getTemplates();
    });

    return {
      templateSelect,
      isEditTemplate,
      headers,
      templates,
      loading,

      validators: {
        required,
      },
      onDeleteRowItem,
      onEditRowItem,
      t,
      getNameAreas,
      onTemplateEdit,
      onItemSelect,
      onToggleSelectAll,
    };
  },
};
</script>

<style></style>
