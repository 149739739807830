<template>
  <v-btn icon v-bind="$attrs" v-on="$listeners">
    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
  </v-btn>
</template>

<script>
import { mdiDeleteOutline } from "@mdi/js";

export default {
  name: "ButtonDelete",
  setup(props) {
    return {
      icons: {
        mdiDeleteOutline,
      },
    };
  },
};
</script>

<style></style>
