<template>
  <wrapper-form v-model="isFormValid" :model="form" ref="form">
    <v-row ref="template">
      <v-col cols="12" col>
        <area-select v-model="form.area_id" :rules="[validators.isSelected]" />
      </v-col>
      <v-col cols="12" col>
        <FormTextArea
          :label="$t('Task title')"
          :rules="[validators.required]"
          :rows="2"
          v-model="form.title"
        />
      </v-col>
      <v-col cols="12" col>
        <FormTextArea
          :label="$t('Task description')"
          :rules="[validators.required]"
          :rows="5"
          v-model="form.description"
        />
      </v-col>

      <v-col cols="12" col>
        <v-btn
          color="primary"
          type="submit"
          :disabled="!isFormValid"
          @click.prevent="onSubmitForm"
        >
          {{ $t("Save template") }}
        </v-btn>
      </v-col>
    </v-row>
  </wrapper-form>
</template>

<script>
import { reactive, ref, getCurrentInstance } from "@vue/composition-api";

import FormTextArea from "@/components/forms/TextArea";
import WrapperForm from "@/components/general/WrapperForm";
import AreaSelect from "@/components/areas/AreaSelect";
import { required, isSelected } from "@core/utils/validation";
import api from "@/services";
import store from "@/store";

const defaultForm = {
  area_id: null,
  title: null,
  description: null,
};

export default {
  name: "TemplateForm",
  components: {
    FormTextArea,
    WrapperForm,
    AreaSelect,
  },
  props: {
    isAdd: Boolean,
    isEdit: Boolean,
    model: Object,
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy;
    const { isAdd, isEdit, model = null } = props;
    const isFormValid = ref(false);

    const form = reactive(model || defaultForm);

    const onFormAdd = async () => {
      try {
        const { data: response } = await api.addTemplates(form);
        const { data, success } = response;

        if (success) {
          store.commit("templates/SET_NEW_TEMPLATE", data);
          vm.$refs.form.$children[0].reset();
        }

        vm.$alert(vm.$t("MessageSuccess"), null, "success");
      } catch (error) {
        vm.$alert(vm.$t("MessageError"), null, "error");
      }
    };

    const onFormUpdate = async () => {
      try {
        const { id: templateId } = model;
        const { data: response } = await api.updateTemplatesById(
          templateId,
          model
        );
        const { success, data: template } = response;

        if (success) emit("on-template-edit", { template });

        vm.$alert(vm.$t("MessageSuccess"), null, "success");
      } catch (error) {
        vm.$alert(vm.$t("MessageError"), null, "error");
      }
    };

    const onSubmitForm = () => {
      if (isAdd) onFormAdd();
      if (isEdit) onFormUpdate();
    };

    return {
      form,
      isFormValid,

      validators: {
        required,
        isSelected,
      },

      onSubmitForm,
    };
  },
};
</script>

<style></style>
